import { Directive, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
declare var M: any;

@Directive({
  selector: '[appMaterialbox]'
})
export class MaterialboxDirective implements AfterViewInit, OnDestroy {

  @Output() opened = new EventEmitter<boolean>();

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    M.Materialbox.init(this.el.nativeElement, {
      onOpenStart: () => {
        this.opened.emit(true);
      },
      onCloseEnd: () => {
        this.opened.emit(false);
      }
    });
  }

  ngOnDestroy() {
    M.Materialbox.getInstance(this.el.nativeElement).destroy();
  }

}
