import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var AOS: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {

  animationEvent = this.whichAnimationEvent();
  pause = false;
  loaded = false;
  bg = "https://i.imgur.com/KRU9ifJm.jpg";
  numbers = [
    {
      "change":false,
      "url": "https://i.imgur.com/KRU9ifJ"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/4yKkRFb"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/t6u9y0Y"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/dpB96x8"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/8oJrqEt"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/l22QOov"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/pB5Ximd"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/fBM4b4m"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/pGDRllk"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/8aiJngF"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/hT1DT88"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/67JDXXa"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/IuKSgYA"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/Q2XCAp7"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/Jmryovz"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/UUHTCA8"
    },
    {
      "change":false,
      "url": "https://i.imgur.com/CfMcJ1y"
    },
  ];
  whichAnimationEvent(){
    let t: any;
    let el = document.createElement("fakeelement");

    var animations = {
      "animation"      : "animationend",
      "OAnimation"     : "oAnimationEnd",
      "MozAnimation"   : "animationend",
      "WebkitAnimation": "webkitAnimationEnd"
    }

    for (t in animations){
      if (el.style[t] !== undefined){
        return animations[t];
      }
    }
  }
  
  ngOnInit() {
    AOS.init({
      duration: 2500,
    });
    let el = $('#splash-overlay');
    $(el).one(this.animationEvent, () => {
      setTimeout( () => {
        $('#welcome').remove();
        $('#splash-overlay').remove();
        this.loaded = true;
      }, 1000);
    });
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout( () => {
        var img = new Image();
        img.onload = () => {
          this.bg = "https://i.imgur.com/KRU9ifJ.jpg";
        }
        img.src = 'https://i.imgur.com/KRU9ifJ.jpg';
      }, 1000);
      setTimeout( () => {
        if(!this.loaded){
          $('#welcome').remove();
          $('#splash-overlay').remove();
          this.loaded = true;
        }
      }, 3000);
    });
  }
}
