import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScrollDirective } from './directives/scroll.directive';
import { CarouselDirective } from './directives/carousel.directive';
import { MaterialboxDirective } from './directives/materialbox.directive';

@NgModule({
  declarations: [
    ScrollDirective,
    CarouselDirective,
    MaterialboxDirective,
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
