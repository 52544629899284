import { Directive, ElementRef, AfterViewInit } from '@angular/core';
declare var $: any;
@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements AfterViewInit {

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    $(this.el.nativeElement).click(function(){
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 1500);
        return false;
    });   
  }

}
