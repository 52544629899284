import { Directive, ElementRef, OnDestroy,
          AfterViewInit, Input, HostListener } from '@angular/core';
declare var M: any;

@Directive({
  selector: '[appCarousel]'
})
export class CarouselDirective implements AfterViewInit, OnDestroy {

  @Input() time = 5000;
  @Input() pause = false;
  instance: any;
  interval: any;
  inside: boolean;

  constructor(private el: ElementRef) { }

  @HostListener('mouseenter') mouseover(event :Event)
  {
    this.inside = true;
  }
  @HostListener('mouseleave') mouseleave(event: Event)
  {
    this.inside = false;
  }

  ngAfterViewInit() {

    M.Carousel.init(this.el.nativeElement, {
      duration:	200,
      dist:	-100,
      shift:	0,
      padding:	0,
      numVisible:	5,
      fullWidth:	false,
      indicators:	false,
      noWrap:	false
    });
    this.instance = M.Carousel.getInstance(this.el.nativeElement);
    this.interval = setInterval( () => {      
      if (!this.inside && !this.pause) {
        this.instance.next();
      }
    }, this.time);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    M.Carousel.getInstance(this.el.nativeElement).destroy();
  }

}
